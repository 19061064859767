import React from 'react';

import 'assets/scss/primers.scss';
import Navbar from '../common/Navbar';
import BreadcrumbTwo from '../common/BreadcrumbTwo';
import FooterPrimer from '../common/FooterPrimer';
import { Table } from 'reactstrap';

const Cromatografia = (props) => {

  return (
    <div className="homepage-2">


      <div className="main">
        {/* Header Navbar */}
        <Navbar />

        {/* breadcrumb Area */}
        <BreadcrumbTwo title="CROMATOGRAFÍA" breadcrumb="Cromatografía" />

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container-primers">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                  <article className="single-post-details plr_md--60 plr_sm--60">

                      <p>
                        <strong>LA QUÍMICA DE LOS COLORES – Sección II</strong>
                      </p>
                      <p>
                        <strong>¿Por qué las hojas de las plantas cambian de color según la estación del año?</strong>
                      </p>
                      <p>
                        La característica externa más notable de los vegetales probablemente sea su <strong>color</strong>,
                        aportándole a los mismos, propiedades llamativas. Los pigmentos que condicionan estos colores están
                        estrechamente ligados con las <em>actividades fisiológicas</em> del propio vegetal. Por ello resulta
                        interesante conocer los <em>pigmentos vegetales</em> que estos contienen, para comprender algunos
                        hechos que observamos en la naturaleza, como por ejemplo, los cambios de colores que las plantas
                        experimentan según la estación del año.
                      </p>
                      <p>
                        Podemos agrupar en tres grupos a los pigmentos que componen los vegetales y son responsables de estos
                        cambios: clorofila, carotenoides y antocianinas.
                      </p>
                      <ul type="disc">
                        <li>
                          La <strong>clorofila</strong> es el principal y es el que da el color verde tradicional de la
                          primavera y verano, esta permite que las plantas usen la energía solar para producir su propio
                          alimento. Sin clorofila no hay fotosíntesis. Las plantas requieren de temperaturas templadas y luz
                          solar para producir clorofila, en otoño, la cantidad producida decrece y la clorofila se va
                          descomponiendo lentamente, haciendo que las hojas pierdan su verdor.
                        </li>
                        <li>
                          Las <strong>xantófilas</strong>, una subclase de <u>carotenoides</u>, son responsables del color
                          amarillo otoñal. Una de las principales xantófilas, la <em>luteína</em>, es además la que le
                          confiere el color amarillo a la yema de huevo.
                        </li>
                        <li>
                          Los <strong>carotenos</strong>, la segunda clase de la familia de los <u>carotenoides</u>, son los
                          responsables de los colores naranja de los vegetales, por ejemplo, las zanahorias. Los carotenoides
                          de las hojas comienzan a degradarse al mismo tiempo que la clorofila, pero lo hacen a un ritmo
                          mucho más lento que la clorofila.
                        </li>
                        <li>
                          Las <strong>antocianinas,</strong> al contrario de los carotenoides, comienzan su síntesis al
                          principio del otoño, a medida que la concentración de azúcar de las hojas aumenta, son las
                          responsables del color de las manzanas, cerezas, frambuesas, entre otros. No todas las especies
                          vegetales producen antocianinas, pero sí clorofila y carotenos.
                        </li>
                      </ul>
                      <p>
                        Veamos en la Tabla 1, diferentes pigmentos vegetales, su color característico y dónde podemos
                        encontrarlos.
                      </p>
                      <Table bordered borderless hover responsive striped align="center">
                        <thead>
                          <tr>
                            <td>
                              <strong>PIGMENTO</strong>
                            </td>
                            <td>
                              <strong>COLOR</strong>
                            </td>
                            <td>
                              <strong>VEGETALES</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <p>Clorofila A</p>
                            </td>
                            <td>
                              <p>Verde azulado</p>
                            </td>
                            <td>
                              <p>plantas y algas</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Clorofila B</p>
                            </td>
                            <td>
                              <p>Verde amarillento</p>
                            </td>
                            <td>
                              <p>plantas y algas</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Carotenos</p>
                            </td>
                            <td>
                              <p>Naranja</p>
                            </td>
                            <td>
                              <p>zanahorias - mango</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Xantófilas</p>
                            </td>
                            <td>
                              <p>Amarillo</p>
                            </td>
                            <td>
                              <p>Plantas en general</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Licopeno</p>
                            </td>
                            <td>
                              <p>Rojo</p>
                            </td>
                            <td>
                              <p>tomates - sandías</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Antocianinas</p>
                            </td>
                            <td>
                              <p>Rojizo-Violeta</p>
                            </td>
                            <td>
                              <p>rabanitos-berenjenas-repollo colorado-uvas</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <p align="center">
                        <u>Tabla 1</u>: Pigmentos vegetales.
                      </p>

                      <p>
                        <strong>¿Podemos distinguir los diferentes pigmentos presentes en las especies vegetales?</strong>
                      </p>

                      <p>
                        Claro. Podemos utilizar diferentes técnicas separativas para conocer la composición pigmentaria de
                        alguna especie vegetal. Si utilizamos una <strong>técnica de extracción</strong> y obtenemos una
                        solución lo suficientemente concentrada en pigmentos, observaremos que prevalece por ejemplo un color
                        verde intenso si partimos de hojas de espinaca o acelga. Para poder distinguir qué pigmentos componen
                        esa solución, podemos pensar en una técnica de separación que recibe el nombre de{' '}
                        <strong>Cromatografía.</strong>
                      </p>

                      <p>
                        <strong>¿Qué es la Cromatografía y cuáles son sus orígenes?</strong>
                      </p>

                      <p>
                        La cromatografía es una técnica o método físico de separación muy utilizado por casi todos los
                        químicos en investigación. Actualmente ocupa un lugar importante dentro de los métodos de análisis
                        modernos, dado la enorme utilidad que presenta para separar, identificar y cuantificar distintas
                        especies químicas en diferentes materiales, ya sea éstos de origen natural, procedentes de la
                        industria química, de alimentos, farmacéutica, etc.
                      </p>

                    <br clear="all" />

                    <p align="center">
                      <img
                        align="center"
                        width="268"
                        height="208"
                        src={process.env.PUBLIC_URL + '/img/primers/cromatografia001.png'}
                        alt="cromatografia - fig1 - Mikhael Tswett (1872 – 1919)"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 1:</u> Mikhael Tswett (1872 – 1919).<u></u>
                    </p>
                    <p>
                      Fue descubierta por un botánico ruso, M. Tswett, que en 1903 empleó la técnica para separar los varios
                      componentes coloreados de un extracto de plantas. Sin embargo, pasaron muchos años hasta que este
                      descubrimiento fue plenamente apreciado por los investigadores ocupados en la separación de materiales
                      biológicos y orgánicos. Se lo considera el padre de la cromatografía. Como él separó compuestos
                      coloreados (pigmentos) denominó a la técnica “cromatografía”, ya que este término deriva de las
                      palabras griegas “chrom” (color) y “graphe” (escribir).
                    </p>

                    <br clear="all" />

                    <p>
                      La <strong>cromatografía </strong>es un método físico que permite la separación y caracterización de
                      mezclas complejas con aplicaciones en todas las ramas de la ciencia. Son un conjunto de técnicas
                      basadas en el <u>principio de retención selectiva</u>, cuyas principales funciones básicas son:
                    </p>
                    <p>
                      - <em>Separar</em> componentes de una mezcla para obtenerlos más puros para su posterior utilización.
                    </p>
                    <p>
                      - <em>Medir</em> la proporción de cada uno de esos componentes (cuantificación).
                    </p>
                    <p>
                      <u>¿Cómo se logra esta separación?</u>
                    </p>
                    <p>
                      <u></u>
                    </p>
                    <p>Se debe a la influencia de dos efectos:</p>
                    <p>
                      a) <u>Retención</u>: los componentes de la mezcla son retenidos por una <em>fase estacionaria</em>
                      (fija).
                    </p>
                    <p>
                      b) <u>Desplazamiento</u>: los componentes de la mezcla se movilizan por acción de una
                      <em>fase móvil</em>, que puede ser un líquido o un gas.
                    </p>
                    <p>
                      Los componentes son impulsados por la fase móvil y <em>migran</em> a lo largo de la fase estacionaria
                      desplazándose a diferentes <em>velocidades</em> que tienen que ver con las <em>afinidades</em> de los
                      componentes con ambas fases. Las afinidades químicas se corresponden con las <em>interacciones</em>
                      de las distintas especies químicas presentes en la mezcla. Aquellos componentes que se retienen
                      fuertemente por la fase estacionaria, se mueven lentamente con el flujo de la fase móvil, mientras que
                      los que se unen débilmente a la fase estacionaria se mueven con rapidez junto a la fase móvil. Como
                      consecuencia de esta movilidad diferente, los componentes de la mezcla se van separando como
                      <u>bandas o manchas</u> que pueden ser analizadas cualitativamente o cuantitativamente.
                    </p>
                    <p>
                      Este <u>equilibrio de distribución</u> de los componentes puede estar regido por distintos
                      <em>fenómenos físicos</em>, entre otros: adsorción, partición, intercambio iónico, bioafinidad y
                      diferencias de tamaño molecular.
                    </p>
                    <p>
                      Por otro lado, las distintas técnicas cromatográficas se pueden llevar a cabo utilizando una variedad
                      de <u>soportes físicos</u>: en papel, sobre placas de vidrio recubiertas con un sólido finamente
                      dividido (gel de sílice), columnas de vidrio o acero inoxidable, etc.
                    </p>
                    <p>
                      <strong>
                        ¿Podemos separar pigmentos vegetales con los materiales de la caja utilizando algún método
                        cromatográfico?
                      </strong>
                    </p>

                    <p>
                      En esta práctica realizaremos una cromatografía para separar los pigmentos presentes en diversos
                      extractos vegetales obtenidos por extracción directa con alcohol etílico.
                    </p>
                    <p>Procedemos del siguiente modo:</p>

                    <p>
                      <u>Técnica 1:</u>
                    </p>

                    <br clear="all" />

                    <p>
                      1- Los extractos vegetales procedentes de la extracción alcohólica, se filtran con embudo de vidrio y
                      papel de filtro y se recolectan en un vaso de precipitado.
                    </p>
                    <p>
                      2- Tomar con un capilar una pequeña cantidad del extracto anterior y sobre un rectángulo de papel de
                      filtro de unos 6 centímetros de ancho por 10 centímetros de alto se traza con lápiz una línea de
                      siembra a 2 centímetros de la base aproximadamente.
                    </p>
                    <p>
                      3- Sobre la línea de siembra se realizan de 5 a 10 toques (descarga de gotitas) con el capilar cargado
                      de pigmento, dejando entre cada toque que se evapore el líquido.
                    </p>
                    <p>
                      4- Se coloca el papel ya sembrado en un vaso de precipitado que contendrá el solvente separador
                      (alcohol etílico), dejándolo unos 5 a 10 minutos.
                    </p>
                    <p align="center">
                      <img
                        width="131"
                        height="205"
                        src={process.env.PUBLIC_URL + '/img/primers/cromatografia002.jpg'}
                        alt="cromatografia - fig2 - Técnica de siembra"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 2</u>: Técnica de siembra.
                    </p>

                    <br clear="all" />

                    <p>5- Los pigmentos se irán separando según su afinidad con el solvente y con el papel (Figura 2).</p>
                    <p>
                      6- Observar las manchas presentes en el papel donde se ha realizado la cromatografía (“cromatograma”).
                    </p>
                    <p>
                      7- Las distintas manchas corresponderán a los diferentes pigmentos presentes en la especie vegetal.
                    </p>
                    <p>
                      <u>Técnica 2:</u>
                    </p>
                    <p>1- Este paso es idéntico al punto 1 de la técnica anterior.</p>

                    <br clear="all" />

                    <p>
                      2- Se corta un rectángulo de papel de filtro de unos 15 centímetros de ancho por 10 centímetros de alto
                      doblado en V (para que se mantenga en pie) y se sumerge en una placa de Petri (Figura 4.a) o en un vaso
                      de precipitado (Figura 4.b) que contiene directamente el filtrado del extracto vegetal alcohólico.
                      Dejar reposar entre 20 y 30 minutos.
                    </p>

                    <p align="center">
                      <img
                        width="286"
                        height="160"
                        src={process.env.PUBLIC_URL + '/img/primers/cromatografia003.jpg'}
                        alt="cromatografia - fig4.a - cromatografía en placa de Petri"

                        hspace="12"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 4.a:</u> cromatografía en placa de Petri.
                    </p>
                    <br clear="all" />

                    <p align="center">
                      <img
                        width="109"
                        height="161"
                        src={process.env.PUBLIC_URL + '/img/primers/cromatografia004.jpg'}
                        alt="cromatografia - fig4.b - cromatografía en vaso de precipitado"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 4.b:</u> cromatografía en vaso de precipitado.
                    </p>

                    <br clear="all" />

                    <p>
                      3- Los pigmentos se irán separando según su afinidad con el solvente en bandas de diferentes colores
                      (Figura 5) para generar el cromatograma correspondiente (Figura 6).
                    </p>
                    <p>
                      · Anotar los resultados según las observaciones identificando los pigmentos vegetales encontrados
                      durante las experiencias. <strong></strong>
                    </p>
                    <p>
                      · Indicar el orden de solubilidad de los pigmentos de acuerdo a su aparición en el papel. Correlacionar
                      este orden con las estructuras químicas de los pigmentos y elaborar conclusiones de los hallazgos
                      obtenidos.<strong></strong>
                    </p>

                    <p>
                      Por ejemplo, para una <u>hoja de espinaca</u> encontraremos:
                    </p>

                    <br clear="all" />

                    <p align="center">
                      <img
                        width="243"
                        height="183"
                        src={process.env.PUBLIC_URL + '/img/primers/cromatografia005.jpg'}
                        alt="SEPARACIÓN DE PIGMENTOS VEGETALES POR CROMATOGRAFÍA SOBRE PAPEL."
                      />
                    </p>
                    <p align="center">
                      <strong>
                        <u>Figura 5:</u>
                      </strong>
                      Pigmentos vegetales.
                    </p>
                    <br clear="all" />
                    <p align="center">
                      <img
                        width="166"
                        height="182"
                        src={process.env.PUBLIC_URL + '/img/primers/cromatografia006.png'}
                        alt="cromatografia - fig6 - Cromatograma"
                      />
                    </p>
                    <p align="center">
                      <strong>
                        <u>Figura 6:</u>
                      </strong>
                      Cromatograma.
                    </p>
                    <br clear="all" />

                    <p>
                      <strong>BIBLIOGRAFÍA</strong>
                    </p>
                    <ul>
                      <li>
                        · Badui Dergal, S. (2006). Química de los alimentos. Cuarta edición. México: Pearson Educación.
                      </li>
                      <li>
                        · Chiroy, H.; Ulín L. (2018). Manual de Prácticas de Laboratorio de Química Orgánica. Universidad
                        Rural de Guatemala.
                      </li>
                      <li>
                        · Corzo, A. (2019). Técnicas de Análisis en Química Orgánica. Cromatografía. Universidad Nacional de
                        Santiago del Estero. Facultad de Ciencias Forestales.
                      </li>
                      <li>
                        · Kurman, L. G. (1990). Química Orgánica. Fundamentos teórico-prácticos del laboratorio. Buenos
                        Aires: Eudeba.
                      </li>
                      <li>
                        · Larpin, D. (2019). Cromatografía de adsorción. Guía de Trabajo Práctico de Química Orgánica.
                        Facultad de Bioquímica y Ciencias Biológicas. Universidad Nacional del Litoral.
                      </li>
                      <li>
                        · Cardoso, M. y de la Torre, J. (1987). Cromatografía de partición en papel. Guía de Trabajo Práctico
                        de Química Orgánica. Facultad de Bioquímica y Ciencias Biológicas. Universidad Nacional del Litoral.
                      </li>
                    </ul>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Footer */}
        <FooterPrimer horizontal="horizontal" />
      </div>
    </div>
  );
};

export default Cromatografia;
