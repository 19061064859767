import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const FooterPrimer = (props) => {
  const { horizontal } = props;

  const formContact = useRef();
  const formSubscribe = useRef();

  const [values, setValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [emailSubscribe, setEmailSubscribe] = useState('');

  const [status, setStatus] = useState('');

  useEffect(() => {
    if (status === 'SUCCESS') {
      setTimeout(() => {
        setStatus({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
      }, 3000);
    }
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TPL_CONTACT_ID, values, process.env.REACT_APP_PUBLIC_KEY)
      .then(
        (response) => {
          console.log('SUCCESS!', response);
          setValues({
            name: '',
            email: '',
            subject: '',
            message: '',
          });

          Swal.fire({
            title: 'Contacto exitoso!',
            text: 'Gracias por contactarse!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
          setStatus('SUCCESS');
        },
        (error) => {
          console.log('FAILED...', error);
          Swal.fire({
            title: 'Ha ocurrido Error!',
            text: 'Intente más tarde. Disculpe las molestias',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        }
      );
  };

  const handleSubscribeSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TPL_SUBSCRIPTION_ID,
        { email: emailSubscribe },
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response);
          setEmailSubscribe('');

          Swal.fire({
            title: 'Subscripción exitosa!',
            text: 'Gracias por subscribirse!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        },
        (error) => {
          console.log('FAILED...', error);
          Swal.fire({
            title: 'Ha ocurrido Error!',
            text: 'Intente más tarde. Disculpe las molestias',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        }
      );
  };

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubscribeChange = (e) => {
    setEmailSubscribe(e.target.value);
  };

  return (
    <>
      <div className={`footer-area-primer ${horizontal}`} id="support">
        <div className="footer-bg" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--40">
                <h2 className="text-white">
                  SEGUIR <span className="theme-color">EN CONTACTO</span>
                </h2>

                <p className="text-white">
                  Te dejamos un formulario de contacto para que nos cuentes tus experiencias o si tienes alguna consulta.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-xl-4 offset-xl-1">
              <div className="contact-inner">
                <h3 className="title">Dí hola!</h3>
                <form className="contact-form" ref={formContact} onSubmit={handleSubmit} noValidate="novalidate">
                  <div className="input-box">
                    <input type="text" name="name" placeholder="Nombre" onChange={handleChange} value={values.name} />
                  </div>

                  <div className="input-box">
                    <input type="text" name="email" placeholder="Email" onChange={handleChange} value={values.email} />
                  </div>

                  <div className="input-box">
                    <input type="text" name="subject" placeholder="Asunto" onChange={handleChange} value={values.subject} />
                  </div>

                  <div className="input-box">
                    <textarea name="message" placeholder="Mensaje" onChange={handleChange} value={values.message}></textarea>
                  </div>

                  <div className="input-box">
                    <button className="submite-button" type="submit">
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-2 col-xl-4 offset-xl-2 mt_md--40 mt_sm--40">
              <div className="contact-inner">
                <h3 className="title">Contacto</h3>
                <div className="conatct-info">
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="zmdi zmdi-phone"></i>
                    </div>
                    <div className="contact-text">
                      <span>--</span>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="zmdi zmdi-globe-alt"></i>
                    </div>
                    <div className="contact-text">
                      <span>
                        mailto:consultaslqra@gmail.com
                        <br />
                        https://lqra.ar/
                      </span>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="zmdi zmdi-pin"></i>
                    </div>
                    <div className="contact-text">
                      <span>
                        UNL,
                        <br /> Ruta Nacional 168, km 0.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
              <div className="newsletter text-center">
                <h3 className="text-white title">SUBSCRIBIRSE PARA ACTUALIZACIONES</h3>
                <p className="text-white">Subscribirse para recibir actualizaciones del proyecto.</p>
                <form ref={formSubscribe} onSubmit={handleSubscribeSubmit} id="newsletter">
                  <div className="newsletter-content">
                    <input
                      type="text"
                      name="emailSubscribe"
                      placeholder="Ingresar tu email"
                      onChange={handleSubscribeChange}
                      value={values.emailSubscribe}
                    />
                    <button type="submit" className="button">
                      <span>Subscribirse</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-xl-4 offset-xl-4">
              <div className="footer-text-primer text-center">
                <span>
                  Proyecto financiado por la Agencia Santafesina de Ciencia, Tecnología e Innovación, dependiente del
                  Ministerio de Ciencia, Tecnología e Innovación productiva de Santa Fe.
                </span>
                <hr />
                <br />{' '}
                <span>
                  Copyright © {new Date().getFullYear()} <a href="/#">LQRA</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tap-top">
        <div>
          <i className="zmdi zmdi-long-arrow-up"></i>
        </div>
      </div>
    </>
  );
};

export default FooterPrimer;
