import React, { useState, useRef, useEffect } from "react";
import emailjs from '@emailjs/browser';
import InputField from './InputField';
import Swal from 'sweetalert2';
import TextareaField from "./TextareaField";

const ContactForm = ()  => {
  const formContact = useRef()

  const [values, setValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [status, setStatus] = useState('');

  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      }, 3000);
    }
  }, [status]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send(
			process.env.REACT_APP_SERVICE_ID, 
			process.env.REACT_APP_TPL_CONTACT_ID, 
			values, 
			process.env.REACT_APP_PUBLIC_KEY
		).then(
      (response) => {
        console.log('SUCCESS!', response);
        setValues({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        
				Swal.fire({
					title: 'Contacto exitoso!',
					text: 'Gracias por contactarse!',
					icon: 'success',
					confirmButtonText: 'Ok'
				})
        setStatus('SUCCESS');
      },
      (error) => {
        console.log('FAILED...', error);
				Swal.fire({
					title: 'Ha ocurrido Error!',
					text: 'Intente más tarde. Disculpe las molestias',
					icon: 'error',
					confirmButtonText: 'Ok'
				})
      }
    );
  };

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="contact-box text-center">
        <form
          ref={formContact}
          onSubmit={handleSubmit}
          className="contact-form"
          noValidate="novalidate"
        >
          <div className="row">
            <div className="col-12">
              <InputField 
                idInput="inputName"
                value={values.name} 
                handleChange={handleChange} 
                name="name" 
                type="text" 
                placeholder="Nombre" 
              />
              <InputField 
                idInput="inputEmail"
                value={values.email} 
                handleChange={handleChange} 
                name="email" 
                type="email" 
                placeholder="Email" 
              />
              <InputField 
                idInput="inputSubject"
                value={values.subject} 
                handleChange={handleChange} 
                name="subject" 
                type="text" 
                placeholder="Asunto" 
              />
            </div>
            <div className="col-12">
              <TextareaField
                className="form-control"
                name="message"
                handleChange={handleChange}
                value={values.message}
              />
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-lg btn-block mt-3">
                  <span className="text-white pr-3">
                    <i className="fas fa-paper-plane" />
                  </span>
                Enviar Mensaje
              </button>
            </div>
          </div>
        </form>
      </div>
  );
}



export default ContactForm;