import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  height: '300px',
};

const center = { lat: -31.639279, lng: -60.67385 };

const Gmaps = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    // googleMapsApiKey: process.env.REACT_APP_GMAPS_SITE,
    googleMapsApiKey: ''
  });
  return isLoaded ? <GoogleMap center={center} zoom={15} mapContainerStyle={containerStyle} /> : <></>;
};

export default Gmaps;
