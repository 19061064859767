import React from 'react'
import 'assets/scss/primers.scss';
import Navbar from './Navbar'
import BreadcrumbTwo from './BreadcrumbTwo'
import FooterPrimer from './FooterPrimer'

const Articulo = (props) => {
  const {title, breadcrumb, content} = props

  return (
    <div className="homepage-2">


      <div className="main">
        {/* Header Navbar */}
        <Navbar />

        {/* breadcrumb Area */}
        <BreadcrumbTwo title={title} breadcrumb={breadcrumb} />

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container-primers">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                <article
                  className="single-post-details plr_md--60 plr_sm--60"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Footer */}
        <FooterPrimer horizontal="horizontal" />
      </div>
    </div>
  )
}

export default Articulo
