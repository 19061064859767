import React from 'react';

const Header = (props) => {

  return (
    <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
      <div className="container position-relative">
        <a className="navbar-brand" href="/">
          <img className="navbar-brand-regular logo" src={process.env.PUBLIC_URL + "/img/muestra2.png"} alt="brand-logo" />
          <img className="navbar-brand-regular logo-unl-sf" src={process.env.PUBLIC_URL + "/img/unl_identidad.svg"} alt="brand-logo" />
          <img className="navbar-brand-regular logo-unl-sf" src={process.env.PUBLIC_URL + "/img/unl3lineas.svg"} alt="brand-logo" />
          <img className="navbar-brand-sticky logo" src={process.env.PUBLIC_URL + "/img/muestra2.png"} alt="sticky brand-logo" />
          <img className="navbar-brand-sticky logo-unl-sf" src={process.env.PUBLIC_URL + "/img/unl3lineas.png"} alt="sticky brand-logo" />
        </a>
        <button
          className="navbar-toggler d-lg-none"
          type="button"
          data-toggle="navbarToggler"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="navbar-inner">
          {/*  Mobile Menu Toggler */}
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <nav>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="nav-item">
                <a className="nav-link scroll" href="/#">
                  Inicio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#materials">
                  Materiales
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#features">
                  Características
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#faq">
                  Preguntas
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#team">
                  Equipo
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#contact">
                  Contacto
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
