import React from 'react'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import ThemeOne from 'layouts/theme-one'
// import ThemeTwo from 'layouts/theme-two'
import Hidrodestilacion from 'components/primers/Hidrodestilacion'
import Extraccion from 'components/primers/Extraccion'
import Cromatografia from 'components/primers/Cromatografia'
import Recristalizacion from 'components/primers/Recristalizacion'
import Sublimacion from 'components/primers/Sublimacion'
import Material from 'components/materials/Material'


const PublicRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* <Route element={<ThemeTwo  />} > */}
          <Route path='/3273357638792F423F4528482B4D6251655468576D5A7134743677397A24432646294A404E635266556A586E327235753878214125442A472D4B6150645367566B597033733676397924423F4528482B4D6251655468576D5A7134743777217A25432A46294A404E635266556A586E3272357538782F413F4428472B4B615064' element={<Cromatografia  />}/>
          <Route path='/cromatografia' element={<Cromatografia  />} />
          <Route path='/50645367566B5970337336763979244226452948404D6251655468576D5A7134743777217A25432A462D4A614E645266556A586E3272357538782F413F4428472B4B6250655368566B5970337336763979244226452948404D635166546A576E5A7134743777217A25432A462D4A614E645267556B58703273357538782F413F' element={<Extraccion  />}/>
          <Route path='/extraccion' element={<Extraccion  />}/>
          <Route path='/217A25432A462D4A614E645267556B586E3272357538782F413F4428472B4B6250655368566D5971337336763979244226452948404D635166546A576E5A7234753777217A25432A462D4A614E645267556B58703273357638792F413F4428472B4B6250655368566D597133743677397A244326452948404D635166546A576E' element={<Hidrodestilacion />} />
          <Route path='/hidrodestilacion' element={<Hidrodestilacion />} />
          <Route path='/743677397A24432646294A404E635266556A576E5A7234753778214125442A472D4B6150645367566B59703273357638792F423F4528482B4D6251655468576D5A7134743677397A24432646294A404E635266556A586E327235753878214125442A472D4B6150645367566B597033733676397924423F4528482B4D62516554' element={<Recristalizacion  />} />
          <Route path='/recristalizacion' element={<Recristalizacion  />} />
          <Route path='/5970337336763979244226452948404D635166546A576D5A7134743777217A25432A462D4A614E645267556B58703272357538782F413F4428472B4B6250655368566D5971337436763979244226452948404D635166546A576E5A7234753778217A25432A462D4A614E645267556B58703273357638792F423F4428472B4B62' element={<Sublimacion  />} />
          <Route path='/sublimacion' element={<Sublimacion  />} />

        {/* </Route> */}
          <Route path='/materials/:id' element={<Material />} />
          <Route path="/materials/slug/:slug" element={<Material />} />

        <Route path='/' element={<ThemeOne />} />
        <Route path='/*' element={<ThemeOne />} />
      </Routes>
    </Router>
  )
}

export default PublicRoutes
