import React from 'react';
import Navbar from '../common/Navbar';
import BreadcrumbTwo from '../common/BreadcrumbTwo';
import FooterPrimer from '../common/FooterPrimer';

import 'assets/scss/primers.scss';

const Extraccion = () => {
  return (
    <div className="homepage-2">


      <div className="main">
        {/* Header Navbar */}
        <Navbar />

        {/* breadcrumb Area */}
        <BreadcrumbTwo title="EXTRACCIÓN" breadcrumb="Extracción" />

        {/* Start Blog Details */}
      <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
        <div className="container-primers">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="blog-details-inner">
                <article className="single-post-details plr_md--60 plr_sm--60">

                    <p>
                      <strong>LA QUÍMICA DE LOS COLORES</strong>
                      <strong> - Sección I</strong>
                    </p>
                    <p align="right">“Azul loco y verde loco del lino en rama y en flor.<br />
                    Mareando de oleadas baila el lindo azuleador.<br />
                      Cuando el azul se deshoja, sigue el verde danzador: verde-trébol, verde-oliva y el gayo verde- limón.<br />
                    ¡Vaya hermosura! ¡Vaya el color!”</p>
                    <p align="right">Gabriela Mistral</p>

                    <p>
                      <strong>¿Qué son los pigmentos vegetales y dónde se encuentran?</strong>

                    </p>
                    <p>
                      El ojo humano puede percibir determinados colores, y a cada color le corresponde una medida, la cual se
                      denomina <strong>longitud de onda</strong>. Estas longitudes de onda van desde 400 a 700 nm
                      (nanómetros), y se les denomina <strong>luz visible</strong>.
                    </p>
                    <p>
                      Los pigmentos son biomoléculas capaces de absorber luz selectivamente y de reflejar un color
                      determinado. El color verde característico de las plantas, es debido a la clorofila, que absorbe todos
                      los colores pero refleja el verde.
                    </p>
                    <p>
                      Los <strong>pigmentos vegetales</strong> constituyen el sustrato fisicoquímico que inicia el
                      <strong>proceso fotosintético</strong>. En las células eucariotas, que son aquellas que presentan un
                      núcleo verdadero, estos pigmentos se encuentran en el interior de una organela llamada
                      <em>cloroplasto.</em> Los cloroplastos son <em>plástidos</em>
                      que almacenan pigmentos clorofílicos, siendo los principales la <u>clorofila a</u> y la
                      <u>clorofila b</u>. Asociados a las clorofilas aparecen también dos pigmentos de la familia de los
                      <u>carotenoides</u>: las <u>xantófilas</u> (amarillas) y los carotenos (amarillo-anaranjados).
                    </p>
                    <p>
                      Otro pigmento que le confiere coloraciones rojizo-violeta a las células vegetales son las
                      <u>antocianinas</u>, que se encuentran en <em>células epidérmicas</em> de las plantas superiores.
                    </p>
                    <p>
                      <strong>En las plantas cumplen una función que deviene de su estructura.</strong>
                    </p>
                    <p>
                      Las <strong>clorofilas</strong> son fotorreceptores: tienen por función captar la luz solar para la
                      conversión de la energía lumínica en energía química aprovechable por las plantas (fotosíntesis).
                      Presentan una estructura compleja formada por un núcleo tetrapirrólico (Figura 1), que incluye en el
                      centro a un ión magnesio (II), coordinado con cuatro átomos de nitrógenos de dicho núcleo. Esto hace a
                      la clorofila un complejo extraordinariamente estable. Una cadena de fitol (alcohol de 20 átomos de
                      carbono), completa la molécula.
                    </p>
                    <p>
                      Los <strong>carotenoides</strong> actúan como pigmentos accesorios que captan luz de una longitud de
                      onda distinta a la captada por la clorofila. <em></em>
                    </p>
                    <p align="center">
                      <img
                        width="297"
                        height="198"
                        src={process.env.PUBLIC_URL + '/img/primers/extraccion001.jpg'}
                        alt="fig1.a - Estructura química de la clorofila a y b, estructuras de carotenoides naturales"
                      />
                      <img
                        width="266"
                        height="159"
                        src={process.env.PUBLIC_URL + '/img/primers/extraccion002.png'}
                        alt="fig1.b - Estructura química de la clorofila a y b, estructuras de carotenoides naturales"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 1</u>: Estructura química de la clorofila a y b, estructuras de carotenoides naturales.
                    </p>


                    <p>
                      Los <u>carotenoides</u> son moléculas hidrocarbonadas de gran longitud, con numerosos dobles enlaces
                      alternados (polienos), que comunican la mayoría de los colores amarillo y rojo de los frutos y flores y
                      aportando el tono otoñal cuando las moléculas de clorofila se degradan y los carotenoides se destacan.
                      Las <u>xantófilas</u> tienen una estructura similar a los carotenos con incorporación de oxígeno.
                    </p>
                    <p>
                      Las clorofilas como los carotenoides son <u>insolubles </u>en <em>agua</em> y <u>solubles</u> en
                      <em>solventes orgánicos.</em>
                    </p>
                    <p>
                      Las <strong>antocianinas </strong>son pigmentos <u>hidrosolubles</u>. Estructuralmente poseen anillos
                      fenólicos y suelen unirse a azúcares (Figura 2). Pertenecen a la familia de los <em>flavonoides.</em>
                      Se encuentran en las vacuolas y dan color a los pétalos de las flores para atrer a insectos
                      polinizadores.
                    </p>
                    <p align="center">
                      <img
                        width="500"
                        height="130"
                        src={process.env.PUBLIC_URL + '/img/primers/extraccion003.png'}
                        alt="Antocianinas de importancia en alimento"
                      />
                    </p>

                    <p align="center">
                      <u>Figura 2</u>: Antocianinas de importancia en alimentos.
                    </p>
                    <p>
                      <strong>¿Por qué su consumo es importante para nuestra salud?</strong>
                    </p>
                    <p>
                      Las frutas y hortalizas frescas son componentes vitales de la dieta, ya que además de aportar color,
                      aroma y sabor a las preparaciones culinarias, satisfacen importantes necesidades nutricionales por ser
                      valiosas fuentes de vitaminas, minerales, fibras y otros <strong>componentes bioactivos</strong>. Los
                      compuestos bioactivos, previenen el envejecimiento celular, además en el caso de los
                      <u>carotenoides </u>los mismos son precursores de la <u>vitamina A</u>, que tiene múltiples beneficios
                      para la salud como participar activamente en la formación y el mantenimiento de dientes, de tejidos
                      blandos y óseos, como así también de las membranas mucosas y de la piel. Otras funciones de la vitamina
                      A están relacionadas con la mejora de la visión nocturna y con el fortalecimiento del sistema
                      inmunitario. La incorporación de las antocianinas como colorantes alimenticios, constituye un beneficio
                      para la salud, en cuanto tiene acciones vasoprotectoras, antidiabéticas y de mejora en la visión.
                    </p>
                    <p>
                      <strong>¿Se puede extraer pigmentos de especies vegetales?</strong>
                    </p>

                    <p>
                      Como vimos en párrafos anteriores, los pigmentos vegetales presentan diferentes solubilidades en los
                      diversos solventes como el agua, el alcohol etílico y la acetona. La mayoría son
                      <em>insolubles en agua</em>, excepto las antocianinas. A los solventes que permiten extraer
                      simultáneamente a todos los pigmentos de las hojas se les llama solventes <strong>extractantes</strong>
                      , en cambio a los solventes que presentan afinidad selectiva por algunos pigmentos, se los llama
                      <strong>separadores</strong>, como por ejemplo el éter de petróleo y el tetracloruro de carbono.
                    </p>

                    <p>
                      <strong>¿En qué consiste una técnica de extracción con solventes?</strong>
                    </p>
                    <p>
                      <strong>Extracción:</strong>
                      es la operación mediante la cual se transfiere una sustancia desde el material líquido o sólido que la
                      contiene, a un solvente que tenga afinidad con dicho material. La extracción es muy utilizada para
                      separar un producto orgánico de una mezcla de reacción o aislarlo de sus fuentes naturales. Así, por
                      extracción se aíslan y purifican numerosos productos naturales, como: vitaminas, alcaloides, hormonas,
                      pigmentos, etc.
                    </p>
                    <p>
                      Existen diferentes tipos de técnicas extractivas, dependiendo el tipo de material, las distintas fases
                      que entran en contacto en el proceso y los fundamentos de los principios de separación. Así tenemos:
                      extracciones líquido – líquido, sólido – líquido, extracción ácido -base, por citar algunos ejemplos.
                    </p>
                    <p>
                      Para el caso de la <em>extracción de pigmentos</em> a partir de una especie vegetal, podemos realizar
                      una <strong>extracción sólido – líquido. </strong>Las técnicas de extracción sólido-líquido,
                      básicamente, ponen en contacto íntimo el sólido (en el que se halla contenido el compuesto a extraer) y
                      el solvente extractor adecuado. Éste disuelve selectivamente el compuesto de interés formando una
                      solución que puede separarse del sólido residual (insoluble en ese solvente) mediante
                      <em>filtración</em>. Esto es factible solo si el compuesto a extraer resulta muy soluble en el solvente
                      extractor y no existen problemas para llegar hasta la intimidad del material sólido en donde se halla
                      el compuesto a extraer. Existen algunas ligeras variantes en las formas en las que se contactan el
                      sólido y el solvente: disolución, maceración, digestión, infusión, decocción, lixiviación, etc.

                    </p>
                    <p>
                      Para la <em>extracción de pigmentos</em>, podemos utilizar la <strong>maceración</strong>, un proceso
                      de disolución por el cual el sólido y el solvente, a temperatura ambiente, se dejan en contacto un
                      tiempo prolongado. Al cabo de ese tiempo se obtiene el <em>sólido residual</em> y el <em>extracto</em>
                      de la sustancia buscada. La maceración se favorece, en general, agitando frecuentemente el recipiente
                      cerrado que contiene la mezcla.
                    </p>
                    <p>
                      <strong>¿Podemos extraer pigmentos vegetales con los materiales de la caja?</strong>
                    </p>
                    <p>
                      En esta práctica realizaremos una <u>extracción directa con solvente</u> de los pigmentos contenidos en
                      diversas especies vegetales, por ejemplo: achicoria, acelga, espinaca, repollo colorado y zanahoria.
                      Para ello necesitaremos utilizar como materiales: mortero y pilón, vaso de precipitado, embudo de
                      vidrio, varilla de vidrio, papel de filtro, hojas frescas de los vegetales seleccionados y alcohol
                      etílico como solvente extractante. Podemos resumir los pasos de la técnica del siguiente modo:
                    </p>


                  <br clear="all" />

                    <p>
                    1- Lavar las hojas de espinaca o acelga frescas, retirar las nervaduras y trozarlas con la mano o con
                      ayuda de una tijera.
                    </p>
                    <p>2- Colocarlas en un mortero junto con el solvente extractante (alcohol etílico) (Figura 3).</p>
                    <p>
                      3- Triturar con ayuda del pilón las hojas, tratando de que entren en íntimo contacto con el solvente
                      extractor. Esta operación debe realizarse hasta obtener un extracto bastante concentrado de la solución
                      que contiene los pigmentos a estudiar. Durante este procedimiento comenzaremos a ver cómo se van
                      decolorando gradualmente las hojas y la solución resultante comienza a colorearse.
                    </p>

                    <p align="center">
                      <image
                        src={process.env.PUBLIC_URL + '/img/primers/extraccion004.jpg'}
                        alt="Obtención del extracto"
                        align="center"
                        hspace="12"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 3</u>: Obtención del extracto.
                    </p>
                  <br clear="all" />

                    <p>4- Observar el color que adquiere la solución resultante (extracto).</p>
                    <p>5- Una vez esté bien coloreada la solución, pasar la misma con cuidado a un vaso de precipitado.</p>

                  <br clear="all" />

                    <p>
                      6- Filtrar la solución contenida en el vaso de precipitado utilizando un embudo de vidrio conteniendo
                      un embudo de papel de filtro. De ser necesario, ayudarse con varilla de vidrio (Figura 4).
                    </p>
                    <p>7- El filtrado se recoge en otro vaso de precipitado o recipiente adecuado.</p>
                    <p align="center">
                      <img

                        align="center"
                        src={process.env.PUBLIC_URL + '/img/primers/extraccion005.jpg'}
                        alt="Filtración del extracto"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 4</u>: Filtración del extracto.
                    </p>


                    <br clear="all" />

                  <p>
                    <strong>Conclusión:</strong>
                    En este primer paso de extracción logramos obtener una solución concentrada (extracto) de los pigmentos
                    presentes en las especies vegetales seleccionadas, es importante observar la coloración obtenida luego
                    del procedimiento. Surgen algunos interrogantes:
                  </p>
                  <p>· ¿Podemos distinguir los distintos tipos de pigmentos presentes en el extracto?</p>
                  <ul>
                    <li>¿Hay algún color que prevalece en la solución?</li>
                  </ul>
                  <p>
                    · ¿Crees que es necesario realizar otra experiencia para lograr separar los pigmentos presentes en el
                    extracto?
                  </p>
                  <p>
                    <strong>→</strong>
                    <em>
                      Te proponemos que investigues alguna metodología que permita analizar los diferentes pigmentos
                      presentes en el extracto obtenido.
                    </em>
                  </p>
                  <p>
                    <strong>BIBLIOGRAFÍA</strong>
                  </p>
                  <ul type="disc">

                  <li>· Badui Dergal, S. (2006). Química de los alimentos. Cuarta edición. México: Pearson Educación.</li>
                  <li>
                    · Chiroy, H.; Ulín L. (2018). Manual de Prácticas de Laboratorio de Química Orgánica. Universidad Rural
                    de Guatemala.
                  </li>
                  <li>
                    · Kurman, L. G. (1990). Química Orgánica. Fundamentos teórico-prácticos del laboratorio. Buenos Aires:
                    Eudeba.
                  </li>
                  <li>
                    · Larpin, D. (2019). Extracción con solventes. Guía de Trabajo Práctico de Química Orgánica. Facultad de
                    Bioquímica y Ciencias Biológicas. Universidad Nacional del Litoral.
                  </li>
                    <li>
                    · Mancilla, C. G. E.; Castrejón, C. R.; Rosas, T. M.; Blanco, E. Z. y Pérez, S. l. J. (2012). Extracción
                      y separación de pigmentos vegetales. Universidad del Valle de México.
                    </li>
                  </ul>

                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Footer */}
      <FooterPrimer horizontal="horizontal" />
      </div>
    </div>
  );
};

export default Extraccion;
