import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import InputField from './InputField';
import Swal from 'sweetalert2';

const SubscribeForm = () => {

  const formSubscribe = useRef()

  const [email, setEmail] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send(
			process.env.REACT_APP_SERVICE_ID, 
			process.env.REACT_APP_TPL_SUBSCRIPTION_ID, 
			{email: email}, 
			process.env.REACT_APP_PUBLIC_KEY
		).then(
      (response) => {
        console.log('SUCCESS!', response);
        setEmail('')
        
				Swal.fire({
					title: 'Subscripción exitosa!',
					text: 'Gracias por subscribirse!',
					icon: 'success',
					confirmButtonText: 'Ok'
				})
      },
      (error) => {
        console.log('FAILED...', error);
				Swal.fire({
					title: 'Ha ocurrido Error!',
					text: 'Intente más tarde. Disculpe las molestias',
					icon: 'error',
					confirmButtonText: 'Ok'
				})
      }
    );
  };

  const handleChange = (e) => {
		setEmail(e.target.value)
		
    // setValues((values) => ({
    //   ...values,
    //   [e.target.name]: e.target.value,
    // }));
  };

  return (
		<form ref={formSubscribe} className="subscribe-form" onSubmit={handleSubmit}>
			<div className="form-group">
				<InputField 
					idInput="inputSubscribe"
					value={email}
					handleChange={handleChange} 
					name="email" 
					type="email" 
					aria="emailSubscribe"
					placeholder="Ingresar email" 
				/> 
			</div>
			<button type="submit" className="btn btn-lg btn-block">Subscribirse</button>
		</form>
	);
};

export default SubscribeForm;
