import React from 'react'
import Data from 'data/articulos.json'
import Articulo from 'components/common/Articulo'
import { useParams } from 'react-router-dom';

const Material = () => {

  const { id, slug } = useParams();
  let article;

  if(id) {
    article = Data.materials.find( article => article.id === parseInt(id) );
  }

  if (!article && slug) {
    article = Data.materials.find((article) => article.slug === slug);
  }

  if (!article) {
    return <div> Material no encontrado!!!</div>;
  }

  return (
    <Articulo
      title={article.title}
      breadcrumb={article.breadcrumb}
      content={article.content}
    />
  )
}

export default Material
