import React, { Component } from 'react';
import { Link } from "react-router-dom"
import DB from 'data/db.json'
import { GiErlenmeyer, GiPestleMortar,GiMicroscope,GiFireBowl,GiTestTubes } from 'react-icons/gi';
import { CgBowl } from 'react-icons/cg'
import { PiFunnelBold } from 'react-icons/pi'

class MaterialsSection extends Component {
    state = {
        data: {},
        materialsDataTwo: []
    }
    componentDidMount(){
        this.setState({
            data: DB.MaterialsSection,
            materialsDataTwo: DB.MaterialsSection.materialsDataTwo
        })
    }

    searchIcon(img, alt){

      if (img === 'GiErlenmeyer') {
        return <GiErlenmeyer size={120}/>
      }

      if (img === 'GiTestTubes') {
        return <GiTestTubes size={120}/>
      }

      if (img === 'BiInjection') {
        return <PiFunnelBold size={120}/>
      }

      if (img === 'SlCup') {
        return <CgBowl size={120}/>
      }

      if (img === 'GiFireBowl') {
        return <GiFireBowl size={120}/>
      }

      if (img === 'GiMicroscope') {
        return <GiMicroscope size={120}/>
      }

      if (img === 'GiPestleMortar') {
        return <GiPestleMortar size={120}/>
      }

      return <GiErlenmeyer size={120}/>

    }


    render() {
        return (
            <section id="materials" className="section materials-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2>{this.state.data.headingTwo}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    {this.state.materialsDataTwo.map((item, idx) => {
                        return(

                            <Link to={`/materials/${item.id}`} key={`ft_${idx}`} className="col-12 col-md-6 col-lg-4">
                                {/* Icon Box */}
                                <div className="icon-box text-center p-4">
                                {/* materialsd Icon */}
                                <div className="materialsd-icon mb-3">
                                    {this.searchIcon(item.image, item.name)}
                                </div>
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">{item.title}</h3>
                                    <p>{item.text}</p>
                                </div>
                                </div>
                            </Link>
                        );
                    })}
                    </div>
                </div>
            </section>
        );
    }
}

export default MaterialsSection;
