import React, { Component } from 'react';

class Navbar extends Component {
  render() {
    return (
      <div className="app-header header--transparent sticker" id="main-menu">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-5 col-5">
              <div className="logo">
                <a href={`${process.env.PUBLIC_URL}/`}>
                  <img
                    className="navbar-brand-regular logo"
                    src={process.env.PUBLIC_URL + '/img/muestra2.png'}
                    alt="brand-logo"
                  />
                  <img
                    className="navbar-brand-regular logo-unl-sf"
                    src={process.env.PUBLIC_URL + '/img/unl_identidad.svg'}
                    alt="brand-logo"
                  />
                  <img
                    className="navbar-brand-regular logo-unl-sf"
                    src={process.env.PUBLIC_URL + '/img/unl3lineas.svg'}
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
