import React from 'react';

import 'assets/scss/primers.scss';
import Navbar from '../common/Navbar';
import BreadcrumbTwo from '../common/BreadcrumbTwo';
import FooterPrimer from '../common/FooterPrimer';

const Recristalizacion = () => {
  return (
    <div className="homepage-2">
      <div className="main">
        {/* Header Navbar */}
        <Navbar />

        {/* breadcrumb Area */}
        <BreadcrumbTwo title="RECRISTALIZACIÓN" breadcrumb="Recristalización" />

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container-primers">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                  <article className="single-post-details plr_md--60 plr_sm--60">
                    <p>
                      <strong>EXPERIMENTANDO CON COMPRIMIDOS DE ASPIRINA</strong>
                    </p>
                    <p align="right">“La música pop es la aspirina y el blues son las vitaminas”</p>
                    <p align="right">Peter Tork</p>
                    <p>
                      <strong>¿Qué es la Aspirina?</strong>
                    </p>
                    <p>
                      Es un medicamento cuyo ingrediente farmacéutico activo es el ácido acetilsalicílico. Se utiliza
                      principalmente como analgésico, antipirético y antiinflamatorio.
                    </p>
                    <p>
                      <strong>¿Cómo se descubrió?</strong>
                      <img
                        width="226"
                        height="151"
                        src={process.env.PUBLIC_URL + '/img/primers/recristalizacion001.jpg'}
                        align="left"
                        hspace="12"
                        padding="10"
                        alt="Cuál es el origen de la aspirina? - VIX"
                      />
                    </p>
                    <p>
                      Los antiguos Sumerios, hace unos 4000 años, empleaban cortezas de ciertos árboles (entre ellos el
                      sauce), para curar los dolores. Alrededor del 400 A.C., Hipócrates recomendaba masticar corteza de
                      sauce o realizar un té para aliviar el dolor, principalmente del parto. A mitad del siglo XVIII, Edward
                      Stone realizó experimentos demostrando que la corteza de sauce triturada en forma de polvo molido podía
                      curar la fiebre. Pasaron 70 años más, hasta que Johann Büchner identificó y purificó el compuesto
                      responsable de esas propiedades farmacológicas: la salicina, sustancia amarillenta, cristalina y muy
                      amarga. En ese entonces, los médicos ya la utilizaban rutinariamente para aliviar el dolor, la fiebre y
                      la inflamación. El componente farmacológico de la salicina era el ácido salicílico (del latín:{' '}
                      <em>salix</em>, sauce), el verdadero precursor de la aspirina.
                    </p>
                    <p>
                      En 1853, Charles Gerhardt fue quién logró sintetizar ácido acetilsalicílico, pero en una forma muy
                      inestable e impura, que no despertó mayor interés. Por lo tanto, la investigación se centró en el mismo
                      ácido salicílico aunque presentaba problemas al ser utilizado por su acidez, ya que provocaba
                      irritación de las mucosas de la boca, del esófago y del estómago.
                    </p>

                    <p align="center">
                      <img
                        width="291"
                        height="100"
                        src={process.env.PUBLIC_URL + '/img/primers/recristalizacion002.gif'}
                        alt="Estructuras químicas del ácido salicílico (izq.) y del ácido acetilsalicílico"
                      />
                      <br />
                      <u>Figura 1</u>: Estructuras químicas del ácido salicílico (izq.) y del ácido acetilsalicílico (der.).
                    </p>
                    <p>
                      Hubo muchos intentos por solucionar este inconveniente, hasta que finalmente, en 1897, la compañía
                      farmacéutica Bayer, de la mano de Felix Hoffmann, encontró un nuevo método para obtener ácido
                      acetilsalicílico y comenzó a comercializar el compuesto como un analgésico llamado aspirina (A, de
                      “acetil”, y la raíz “spir” del nombre en latín de la ulmaria: Spirea, vegetal del que se extrae
                      salicilato de metilo). Este fue reconocido ampliamente como uno de los primeros medicamentos
                      farmacéuticos sintéticos.
                    </p>
                    <p>
                      <strong>¿Cómo funciona?</strong>
                    </p>
                    <p>
                      Después de una dosis individual de aspirina, una pequeña proporción se absorbe en el estómago, pero la
                      mayor parte se absorbe más rápidamente en el intestino delgado superior. La aspirina así absorbida se
                      hidroliza en el plasma, eritrocitos y, fundamentalmente hígado, a ácido salicílico que es el principio
                      activo de la droga. Éste actúa principalmente en la inhibición de la producción de las prostaglandinas
                      (sustancias mensajeras de la sensación del dolor), y de Interleucina-1, potente inductor de la
                      inflamación de las zonas afectadas por traumatismos o enfermedades reumáticas. También alivia la fiebre
                      debida a infecciones gracias a su acción dilatadora de vasos sanguíneos. Como antiagregante plaquetario
                      inhibe la formación de trombos conformados por plaquetas y placas lipídicas que deterioran los vasos
                      sanguíneos obstruyendo el normal flujo de la sangre, capaces de provocar accidentes cardiovasculares.
                    </p>
                    <p>
                      <strong>¿Cómo se obtiene?</strong>
                    </p>
                    <p>
                      La aspirina puede sintetizarse fácilmente por acetilación del ácido salicílico en presencia de un
                      catalizador ácido. Luego debe purificarse y acondicionarse como corresponde para cumplir con lo
                      establecido por el organismo regulador del país (ANMAT). La forma de presentación más común de la
                      aspirina es el comprimido que contiene entre 500 y 650 mg de ácido acetilsalicílico mezclado con
                      excipientes, que se utilizan para conseguir la forma farmacéutica deseada (cápsulas, comprimidos,
                      soluciones, etc.) y facilitan la preparación, conservación y administración de los medicamentos.
                      Generalmente para aspirina se utiliza aglomerante inerte (normalmente almidón y celulosa).
                    </p>
                    <p>
                      Si se desea purificar el ácido acetilsalicílico libre de estos excipientes se puede emplear el método
                      de recristalización.
                    </p>
                    <p>
                      <strong>¿Qué es la recristalización?</strong>
                    </p>
                    <p>
                      Es un procedimiento útil para la purificación de sólidos cristalinos, que se fundamenta en la variación
                      de la solubilidad de un sólido en un determinado solvente cuando varía la temperatura (Figura 2). La
                      clave de este procedimiento radica en seleccionar un solvente tal que el sólido a purificar se disuelva
                      muy bien a la temperatura de ebullición del solvente elegido pero muy poco en frío. Por ejemplo, la
                      aspirina es muy soluble en etanol 96% a ebullición pero ligeramente soluble en etanol 96% a 5°C
                      (temperatura del baño de hielo).
                    </p>
                    <p>
                      Otro aspecto clave a considerar son las impurezas que acompañan al sólido. Por un lado, hay impurezas
                      que resultan ser insolubles a alta temperatura en el solvente elegido y deben ser retiradas mediante
                      filtración en caliente para evitar que interfieran al recristalizar el sólido. Este es el caso del
                      almidón y la microcelulosa cristalina presentes como excipientes en el comprimido de aspirina. Por otro
                      lado, hay impurezas que permanecen disueltas en el solvente, aún en frío. Esto significa que al
                      disolver el sólido con las impurezas en el solvente caliente, ambos se disuelven, pero al enfriar la
                      solución resultante, el sólido cristaliza mientras que las impurezas permanecen disueltas. Por
                      filtración logramos separar el sólido de sus impurezas: el compuesto cristalizado ahora puro y en fase
                      sólida se retiene, mientras que las impurezas que lo acompañaban permanecen disueltas en la fase
                      líquida.
                    </p>

                    <p align="center">
                      <img
                        width="268"
                        height="360"
                        src={process.env.PUBLIC_URL + '/img/primers/recristalizacion003.gif'}
                        hspace="12"
                        vspace="12"
                        alt="Pasos de la técnica de recristalización"
                      />
                      <br />
                      <u>Figura 2</u>: Pasos de la técnica de recristalización.
                    </p>
                    <p>
                      <strong>
                        ¿Cómo se realiza la recristalización de comprimidos de aspirina con el contenido de la caja?
                      </strong>
                    </p>
                    <p>
                      La Figura 3 nos indica cómo podemos trabajar con los elementos de la caja. Pulverizar cuatro
                      comprimidos de aspirina comercial en un mortero y conservar una mínima proporción del polvo obtenido
                      para la prueba de Lugol. Transferir el material pulverizado a un vaso de precipitados de 250 mL de
                      capacidad, con ayuda de una cucharita limpia y seca. Se agrega unos 20 mL de etanol 96% a ebullición
                      (80°C) y se continúa calefaccionando con agitación hasta recuperar el hervor y asegurar que la aspirina
                      se haya disuelto completamente. Los excipientes -celulosa microcristalina y almidón- no se disuelven.
                      La mezcla sólido/líquido resultante se filtra. Los excipientes quedan retenidos en el papel de filtro.
                      El filtrado, que es una solución etanólica de aspirina, se recoge en un Erlenmeyer de 250 mL. Se deja
                      enfriar el Erlenmeyer a temperatura ambiente hasta observar la formación de cristales. Si se demorara
                      la recristalización, colocarlo en baño de hielo agitando de vez en cuando. Por último, si se desea
                      recuperar los cristales obtenidos, se realiza una segunda filtración utilizando, esta vez, un nuevo
                      papel de filtro en el mismo embudo previamente lavado con alcohol de 96°. En el filtrado, recogido en
                      un Erlenmeyer de 250mL, quedarán las aguas madres. Los cristales retenidos se lavan con dos porciones
                      de 3 mL de etanol frío y se secan comprimiéndolos sobre papel de filtro. Una vez eliminada la mayor
                      parte del solvente, se deja secar a temperatura ambiente y se procede a realizar la prueba de Lugol.
                    </p>
                    <p></p>
                    <p align="center">
                      <img
                        width="589"
                        height="278"
                        src={process.env.PUBLIC_URL + '/img/primers/recristalizacion004.gif'}
                        alt="Pasos para la recristialización de comprimidos de aspirina."
                      />
                      <br />
                      <u>Figura 3:</u>
                      Pasos para la recristialización de comprimidos de aspirina.
                    </p>
                    <p>
                      <u>Prueba de Lugol:</u>
                    </p>
                    <p>
                      El reactivo de Lugol es una solución de yodo y yoduro de potasio disueltos en agua. El yodo reacciona
                      con el almidón para dar un complejo azul-violeta fuerte. Esto permite que pueda emplearse para
                      diferenciar la aspirina comercial, que contiene almidón entre sus excipientes, de la aspirina
                      recristalizada, que está libre de esas impurezas. Para ello, se hierve en tubos de ensayo, una pequeña
                      cantidad de cada muestra en 2 mL de agua, se deja enfriar y se añade una gota Lugol con el gotero. En
                      nuestro caso, esperamos que el ensayo sea positivo para la aspirina comercial y negativo para la
                      aspirina recristalizada. <u></u>
                    </p>
                    <p>
                      <strong>BIBLIOGRAFÍA</strong>
                    </p>
                    <ul>
                      <li>
                        · Anderson Guarnizo, F. y Martínez Yepes, P. N. 2004. Experimentos de Química Orgánica: con enfoque
                        en ciencias de la vida. Colombia: Ediciones Elizcom.
                      </li>
                      <li>· Blanco, A. 2002. Química Biológica Buenos Aires: Editorial El Ateneo.</li>
                      <li>
                        · Galagovsky Kurman, L. 1999. Química Orgánica: Fundamentos Teórico-Prácticos del Laboratorio. 6°
                        Edición. Buenos Aires: Editorial Eudeba.
                      </li>
                      <li>
                        · Perin, J. C. Guía de Trabajos Prácticos: Obtención de aspirina. Departamento de Química Orgánica.
                        Facultad de Bioquímica y Ciencias Biológicas. Universidad Nacional del Litoral.
                      </li>
                      <li>
                        · Zubrick, J. 1997. Organic chemistry laboratory survival manual: a student’s guide to techniques. 4°
                        Edición. Nueva York: Editorial John Wiley.
                      </li>
                    </ul>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Footer */}
        <FooterPrimer horizontal="horizontal" />
      </div>
    </div>
  );
};

export default Recristalizacion;
