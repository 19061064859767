
const TextareaField = (props) => {
    const { handleChange, label, name, value } = props;
    return (
      <div className="form-group">
        {label? 
          <label className="text-gray-500 text-sm" htmlFor={name}>{label}</label>
        :null}
        <textarea 
          onChange={handleChange} 
          name={name} 
        //  rows="4" 
          className="form-control" 
          value={value}
          placeholder="Mensaje"
          required
        >
            
        </textarea>
      </div>
    )
  }
  
  export default TextareaField