import React, { Component } from 'react'
import SubscribeForm from './contact/SubscribeForm';

const initData = {
    heading: "Subscribirse para actualizaciones",
    text: ""
}

class Subscribe extends Component {

    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }

    render() {
        return (
            <section className="section subscribe-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        <div className="subscribe-content text-center">
                        <h2>{this.state.initData.heading}</h2>
                        <p className="my-4">{this.state.initData.text}</p>
                        {/* Subscribe Form */}
                        <SubscribeForm />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Subscribe;