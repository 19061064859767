import React from 'react'

import { BiArrowToTop } from 'react-icons/bi'

import Header from 'components/Header'
import Hero from 'components/Hero'
import Feature from 'components/Feature'
import Footer from 'components/Footer'
import Subscribe from 'components/Subscribe'
import Contact from 'components/contact/Contact'
import Team from 'components/Team'
import Faq from 'components/Faq'
import Materials from 'components/Materials'

const ThemeOne = () => {
  return (
    <div className='homepage-2'>

        <div id='scrollUp' title='Scroll para arriba'>
          <BiArrowToTop />
        </div>

        <div className='main'>
            <Header />
            <Hero />
            <Materials />
            <Feature />
            <Faq />
            <Team />
            <Subscribe />
            <Contact />
            <Footer />
        </div>
      </div>
  )
}

export default ThemeOne
