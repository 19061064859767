const InputField = (props) => {
    const { idInput,value, handleChange,placeholder, label, name, type, aria } = props;
    return (
      <div className="form-group">
        {label? 
          <label className="text-gray-500 text-sm" htmlFor={name}>{label}</label>
        :null}
        <input 
            type={type} 
            className="form-control" 
            name={name}
            id={idInput} 
            aria-describedby={aria} 
            placeholder={placeholder} 
            onChange={handleChange} 
            value={value} 
            required
        />
      </div>
    )
  }
  
  export default InputField