import React from 'react';

import 'assets/scss/primers.scss';
import Navbar from '../common/Navbar';
import BreadcrumbTwo from '../common/BreadcrumbTwo';
import FooterPrimer from '../common/FooterPrimer';

const Hidrodestilacion = () => {
  return (
    <div className="homepage-2">
      <div className="main">
        {/* Header Navbar */}
        <Navbar />
        {/* breadcrumb Area */}
        <BreadcrumbTwo title="HIDRODESTILACIÓN" breadcrumb="Hidrodestilación" />

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container-primers">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                  <article className="single-post-details plr_md--60 plr_sm--60">
                    <p>
                      <strong>LA QUÍMICA DE LOS SABORES Y LOS AROMAS</strong>
                    </p>
                    <p align="right">“Mi alma nada en los perfumes como la de los otros hombres en la música”</p>
                    <p align="right">Baudelaire</p>
                    <p>
                      <strong>¿Por qué muchas plantas producen fragancias?</strong>
                    </p>
                    <p>
                      Las plantas pueden sintetizar una gran cantidad de compuestos volátiles, responsables de sus fragancias
                      características, y depositarlos a menudo en espacios intercelulares de diversos tejidos o en glándulas.
                      Estas sustancias volátiles, llamadas esencias o aceites esenciales, se concentran en las semillas,
                      flores, hojas y/o los frutos, y están compuestas por una mezcla compleja de diferentes clases de
                      compuestos orgánicos: terpenos, terpenoides, ésteres, éteres, aldehídos, alcoholes, cetonas, por sólo
                      mencionar algunas familias de ellos. Estas esencias desempeñan funciones ecológicas (no fisiológicas):
                      algunas atraen polinizadores a través de los aromas florales, otros ahuyentan a los animales que
                      podrían comérselos (insectos o pájaros), algunas tienen actividad antimicrobiana y, por lo tanto,
                      confieren protección frente a descomponedores.
                    </p>
                    <p>
                      <strong>¿Qué son los aceites esenciales?</strong>
                    </p>

                    <p>
                      Un aceite esencial es una mezcla líquida de compuestos volátiles, de apariencia oleosa, que se obtienen
                      de plantas odoríferas. Estos aceites, llamados también aceites etéreos o volátiles, son insolubles en
                      agua y se caracterizan por tener dos o tres componentes principales en concentraciones relativamente
                      altas, acompañados de otros que aparecen en menor proporción. Los más difundidos son los aceites de
                      anís, clavo de olor, cúrcuma, eucalipto, ajo, jazmín, naranja, limón, pimienta, rosa, sándalo,
                      albahaca, eneldo, citronela, romero, tomillo y lavanda, entre otros.
                    </p>
                    <p align="center">
                      <img
                        src={process.env.PUBLIC_URL + '/img/primers/image-hidrodestilacion-1.png'}
                        alt="hidrodestilacion - fig1"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 1:</u>
                      Los sumerios se interesaron por los aceites esenciales unos 3500 años a.C. Continuaron desarrollando la
                      técnica asirios y babilonios, 2000 a.C., los sacerdotes egipcios, a partir del 1500 a.C., y
                      posteriormente otros pueblos de la antigüedad. El mayor aporte se debe a los árabes, 500 d.C., que
                      emplearon alambiques para obtener aceites esenciales y destilar alcohol. Marco Polo, comerciante
                      veneciano, dio a conocer esas técnicas en Occidente.
                    </p>

                    <p>
                      <strong>¿Para qué se emplean los aceites esenciales?</strong>
                    </p>
                    <p>
                      Los aceites esenciales logran estimular los receptores olfativos, desencadenando sensaciones que van
                      más allá de la percepción del aroma: disparan recuerdos, sentimientos, emociones, etc., configuran una
                      “sinfonía de la naturaleza que deleita y cataliza el bienestar”. Se han reconocido propiedades
                      terapéuticas, refrescantes, estimulantes, sedantes en varios constituyentes. Los aceites esenciales son
                      usados por su atractivo aroma y sabor en la elaboración de perfumes, cosméticos, dentífricos, licores,
                      repostería, bebidas, condimentos, medicamentos, etc. Unos pocos son valorados por su acción
                      antibacteriana y fungicida. Algunos son usados como repelentes de insectos. La trementina, un aceite
                      derivado del exudado de pino, se usa como solvente de pintura.
                    </p>
                    <p>
                      <strong>¿Cómo pueden obtenerse?</strong>
                    </p>
                    <p>
                      Se las extrae habitualmente con vapor de agua o solventes, aunque también mediante la utilización de
                      operaciones mecánicas. El método de extracción con vapor hace pasar una corriente de vapor de agua a
                      través del material vegetal. El vapor transfiere calor a las hojas, semillas, etc., y vaporiza los
                      aceites esenciales. La mezcla de vapores de agua y esencias se retira del equipo para condensarlo
                      (Figura 2).
                    </p>
                    <p align="center">
                      <img
                        src={process.env.PUBLIC_URL + '/img/primers/image-hidrodestilacion-2.png'}
                        alt="hidrodestilacion - fig2"
                      />
                    </p>

                    <p align="center">
                      <u>Figura 2</u>: El dispositivo del dibujo muestra un equipo para obtener aceites esenciales por
                      arrastre con vapor por demás sencillo: se hierve agua en un recipiente y se conducen los vapores
                      generados hasta donde está el material vegetal trozado. El calor llega hasta los tejidos de la planta y
                      evapora los aceites esenciales allí contenidos. Esta mezcla de vapores se conduce hacia un tubo en el
                      que condensan (pasan nuevamente al estado líquido). Finalmente se colecta la esencia, junto a algo de
                      agua, en un recipiente enfriado en hielo.
                    </p>
                    <p>
                      <strong>¿Podemos experimentar con aceites esenciales con los materiales de la caja?</strong>
                    </p>
                    <p>
                      En esta práctica desarrollaremos una de las técnicas para la extracción de aceites esenciales conocida
                      como hidrodestilación. Con los materiales de la caja armaremos el dispositivo de la Figura 3. En un
                      erlenmeyer cerrado con un tapón monohoradado y atravesado por un tubo doblado en “U” generaremos un
                      vapor mixto: el vapor de agua y las esencias que se desprenden desde ese Erlenmeyer pasarán hacia un
                      recipiente colector (tubo grande, erlenmeyer, etc.), inmerso en hielo. El aceite se recuperará del
                      condensado. Se corta en trozos pequeños hojas de eucalipto, o clavos de olor, o cáscaras de mandarina o
                      naranja y se colocan en el erlenmeyer de manera que ocupen no más que la quinta parte de su volumen. Se
                      coloca agua que cubra el material vegetal hasta un tercio de la capacidad del erlenmeyer.
                    </p>
                    <p align="center">
                      <img
                        src={process.env.PUBLIC_URL + '/img/primers/image-hidrodestilacion-3.png'}
                        alt="hidrodestilacion - fig3"
                      />
                    </p>
                    <p align="center">
                      <u>Figura 3:</u>
                      Dispositivo de la práctica. La materia prima mezclada con el agua comienza a hervir y a producir
                      abundante vapor. Ese vapor está compuesto de agua y también de la esencia que se desprende durante la
                      calefacción. Al condensar esos
                      <br />
                      vapores, se separa el aceite y el agua aromática, que es una solución acuosa saturada de la esencia.
                    </p>

                    <p>
                      Se inserta el tapón como muestra la Figura 3 y se coloca el colector en un pequeño baldecito con agua y
                      hielo. Si no se tiene un baldecito puede emplearse un envase de gaseosa de 2 L cortado a una altura
                      conveniente. Una vez verificado el sistema por el Profesor, se comienza la calefacción de manera de
                      lograr una ebullición suave, sin sobresaltos. Conviene montar la tela metálica sobre el calefactor de
                      manera que modere la llegada del calor. Los vapores mixtos (vapor de agua + vapor de aceites
                      esenciales), alcanzan el tubo en “U”, se condensa parcialmente y gotea en el colector. Allí condensarán
                      los vapores que no lo hicieron en el tubo. Tras 3-5 minutos de ebullición se apaga el dispositivo e
                      inmediatamente se retira el colector del tubo en “U” para evitar posibles reabsorciones. Observando
                      detenidamente el líquido colectado se verán dos capas: el aceite esencial (en la mayoría de los casos
                      flotando en pequeñas gotas), y el agua aromática o hidrolato.
                    </p>

                    <p>
                      <strong>
                        <u>Experimentando con esencias en casa…</u>
                      </strong>
                    </p>
                    <p align="center">
                      <img
                        src={process.env.PUBLIC_URL + '/img/primers/image-hidrodestilacion-4.png'}
                        alt="hidrodestilacion - fig4"
                      />
                    </p>
                    <p>
                      El “prensado en frío” para la extracción de aceites esenciales, es una técnica que consiste en aplicar
                      presión sobre el material vegetal odorífero y así retirar los aceites etéreos que aloja. Se toma un
                      frasco pequeño y se acercan a su boca trozos de cáscara de mandarina, naranja o limón. Se colocan estos
                      trozos entre los dedos índice y pulgar y se presiona, con la parte lustrosa frente al tubo. Las
                      pequeñas gotitas de aceite esencial se escurren en el tubo y se conservan en heladera.
                    </p>

                    <p>
                      <strong>BIBLIOGRAFÍA</strong>
                    </p>
                    <ul>
                      <li>
                        · Ali, B., Al-Wabel, N. A., Shams, S., Ahamad, A., Khan, S. A., &amp; Anwar, F. (2015). Essential
                        oils used in aromatherapy: A systemic review. Asian Pacific Journal of Tropical Biomedicine, 5(8),
                        1–11.
                      </li>
                      <li>· Bross, Brunhilde. (1994). Sustancias Aromáticas. Barcelona, España: Ediciones Omega S. A.</li>
                      <li>
                        · Guenther, E. (2007). The Essential Oils - Vol 1: History-Origin in Plants-Production Analysis.
                        London, UK: Jepson Press.
                      </li>
                      <li>
                        · Hamid, A. A., Aiyelaagbe, O. O., &amp; Usman, L. A. (2011). Essential Oils: It´s Medicinal and
                        Pharmacological Uses. International Journal of Current Research, 3(2), 86–98. Malabar, Florida 32950,
                        USA.
                      </li>
                      <li>
                        · Naves, Y. R. (1974). Technologie et chimie des parfums naturels: essences concrètes, résinoi͏̈des,
                        huiles et pommades aux fleurs. Paris, France: Masson &amp; Cie Editeurs.
                      </li>
                      <li>
                        · Ortuño Sanchez, M. (2006). Manual Práctico de Aceites esenciales, aromas y perfumes. Madrid,
                        España: Aiyana Ediciones.
                      </li>
                      <li>
                        · Retamar, J. A. (1988). Química y tecnología de los aromas naturales y sintéticos. Monografías de la
                        Acad. Nac. De Cs. Exactas, Físicas y Nat. 3 (6). 55-69.
                      </li>
                    </ul>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Footer */}
        <FooterPrimer horizontal="horizontal" />
      </div>
    </div>
  );
};

export default Hidrodestilacion;
