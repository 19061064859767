import React from 'react';


const BreadcrumbTwo = (props) => {
  const { title, breadcrumb } = props

  return (
    <>
      <div className="breadcrumb-area-primers ptb--120 bg_image bg_image--3" data-black-overlay="5">
        <div className="container-primers">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <h2 className="h2-primer title">{title}</h2>
                <ul className="page-breadcrumb">
                  <li>
                    <a className="a-primer" href="/">LQRA</a>
                  </li>
                  <li>-</li>
                  <li>{breadcrumb}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreadcrumbTwo;
