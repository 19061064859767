import React from 'react'

import PublicRoutes from 'routes/routes'

function App() {
  return (
    <div className="App">
      <PublicRoutes />
    </div>
  );
}

export default App;
