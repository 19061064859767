import React from 'react';

import 'assets/scss/primers.scss';
import Navbar from '../common/Navbar';
import BreadcrumbTwo from '../common/BreadcrumbTwo';
import FooterPrimer from '../common/FooterPrimer';

const Sublimacion = () => {
  return (
    <div className="homepage-2">


      <div className="main">
        {/* Header Navbar */}
        <Navbar />

        {/* breadcrumb Area */}
        <BreadcrumbTwo title="SUBLIMACIÓN" breadcrumb="Sublimación" />

        {/* Start Blog Details */}
      <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
        <div className="container-primers">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="blog-details-inner">
                <article className="single-post-details plr_md--60 plr_sm--60">
                  <p>
                    <strong>LOS CAMBIOS DE ESTADO DE LA MATERIA</strong>
                  </p>
                  <p align="right">
                    “El té aviva nuestra imaginación. Reprime los vapores que invaden la cabeza y mantiene sereno el palacio
                    del alma”
                  </p>
                  <p align="right">Edmund Waller</p>
                  <p>
                    <strong>¿En cuántas formas diferentes puede existir una misma sustancia?</strong>
                  </p>
                  <p>
                    Los tres estados clásicos en los que puede existir una sustancia son
                    <em>sólido</em>, <em>líquido</em> y <em>gaseoso</em>, y esto está influenciado por la temperatura y
                    presión a la cual se encuentra sometida dicha sustancia, que determinan la organización de las moléculas
                    que conforman la misma.
                  </p>
                  <p>
                    <strong>
                      <em>Veamos un ejemplo típico…</em>
                    </strong>
                  </p>
                  <p>
                    Quizás el ejemplo más utilizado para explicar los cambios de estado de la materia sea el <em>agua</em>.
                  </p>
                  <p>
                    · El estado más usual en el que podemos encontrar este compuesto es el estado líquido, en donde el agua
                    se comporta como un fluido, adoptando, por lo general, la forma del recipiente que lo contiene. El agua
                    contenida en un vaso, en una pileta de natación o en una laguna se encuentra en estado líquido.
                  </p>
                  <p>
                    · Cuando colocamos agua en un recipiente o en una cubetera y la exponemos por un par de horas a
                    temperaturas por debajo de los 0°C, vamos a ver cómo el agua se solidifica, adquiriendo una estructura
                    rígida y compacta. Esta situación es la que se conoce como estado sólido del agua.
                  </p>
                  <p>
                    · Finalmente, cuando colocamos agua líquida en una pava o en una olla de cocina y aplicamos calefacción,
                    vemos como, al cabo de unos minutos, se empiezan a generar vapores, constituyendo el estado gaseoso del
                    agua.
                  </p>

                  <p>
                    <strong>¿Cómo podemos explicar lo anterior?</strong>
                  </p>
                  <p>
                    La materia está constituida por átomos y estos átomos se agrupan entre sí formando moléculas. A estos
                    átomos o moléculas se los puede llamar de manera genérica como <em>partículas</em>, que interactúan entre
                    sí mediante la generación de fuerzas de atracción o de cohesión.
                  </p>
                  <p>
                    · En el estado sólido, las fuerzas de atracción entre partículas son muy grandes. Debido a esto esto, las
                    partículas están muy próximas entre sí, se agrupan de manera ordenada y casi no se mueven, solamente
                    vibran en posiciones fijas. Esto explica el hecho de que los sólidos tengan forma y volumen propios.
                  </p>
                  <p>
                    · En el estado líquido, las fuerzas de atracción entre partículas son menores que en los sólidos, y las
                    partículas ruedan unas sobre otras. Esto explica por qué los líquidos tienen volumen, pero no forma
                    propia, y permite comprender por qué fluyen, es decir, se derraman.
                  </p>
                  <p>
                    · En el estado gaseoso, las fuerzas de atracción son muy pequeñas, por ello las partículas se mueven a
                    mucha velocidad y se alejan entre sí. El movimiento es desorganizado y debido a esto, los gases no tienen
                    forma ni volumen definidos.
                  </p>
                  <p>
                    Los tres estados de la materia pueden interconvertirse entre sí sin que cambie la composición de una
                    sustancia, y estos cambios de estado se ven influenciados por factores ambientales como temperatura y
                    presión. La Figura 1 representa los diferentes cambios de estado de la materia.
                  </p>
                  <p align="center">
                    <img
                      src={process.env.PUBLIC_URL + '/img/primers/sublimacion001.jpg'}
                      alt="extraccion - fig1 - Cambios de estado de la materia"
                    />
                  </p>
                  <p align="center">
                    <u>Figura 1</u>: Cambios de estado de la materia
                  </p>
                  <p>
                    <strong>¿Qué es la sublimación?</strong>
                  </p>
                  <p>
                    La sublimación es un proceso que implica el pasaje de una sustancia del estado sólido al estado gaseoso,
                    o del estado gaseoso al estado sólido, sin pasar por el estado líquido. Es decir, bajo determinadas
                    condiciones de temperatura y de presión, podemos lograr que ciertas sustancias puedan transformarse
                    directamente de sólidas a gaseosas (y viceversa) sin pasar al estado fundido.
                  </p>

                  <p>
                    <strong>¿Qué aplicaciones tiene la sublimación?</strong>
                  </p>
                  <p>
                    Si bien son muy pocas las sustancias que pueden sublimar a presión atmosférica, la sublimación puede
                    aplicarse en un laboratorio como una técnica de purificación de sólidos muy eficaz, aunque los
                    rendimientos obtenidos en este procedimiento suelen ser bastante bajos. El <em>naftaleno</em>, presente
                    en las bolas comerciales de naftalina utilizadas para ahuyentar polillas, o el <em>alcanfor</em>,
                    sustancia cristalina de olor fuerte y penetrante, y que puede emplearse en la elaboración de pomadas con
                    fines analgésicos para músculos y articulaciones, son algunos ejemplos de sustancias sublimables en
                    condiciones ambientales. La <em>cafeína</em>, probablemente el estimulante más antiguamente utilizado por
                    el hombre, también puede purificarse por esta técnica.
                  </p>

                  <p>
                    <strong>¿Qué es la cafeína?</strong>
                  </p>
                  <p>
                    La cafeína (Figura 2) es un compuesto orgánico que puede clasificarse como un alcaloide y que ejerce una
                    acción estimulante sobre el Sistema Nervioso Central. El té, el café, el cacao y la yerba mate son
                    algunos ejemplos de fuentes naturales a partir de donde podemos obtener cafeína. Las bebidas energizantes
                    y la gaseosa de tipo cola mundialmente conocida también son fuente de este alcaloide. Una ingesta
                    excesiva de cafeína puede producir angustia, irritabilidad, insomnio y temblores musculares, pudiendo
                    llegar a ser tóxica para el organismo.
                  </p>
                  <p align="center">
                    <img
                      src={process.env.PUBLIC_URL + '/img/primers/sublimacion002.jpg'}
                      alt="extraccion - fig2 - Estructura de la cafeína, cuyo nombre científico es 1,3,7-trimetilxantina"
                    />
                    <br />
                    <u>Figura 2</u>: Estructura de la cafeína, cuyo nombre científico es 1,3,7-trimetilxantina
                  </p>

                  <p>
                    <strong>¿Podemos sublimar cafeína con los materiales de la caja?</strong>
                  </p>
                  <p>
                    En esta práctica desarrollaremos una técnica de sublimación a presión atmosférica para aislar cafeína a
                    partir de hebras comerciales de té.
                  </p>
                  <p>
                    Con los materiales de la caja, armaremos el dispositivo de la Figura 3. En una cápsula de porcelana,
                    colocaremos un puñado de hebras de té, de manera tal de cubrir aproximadamente la mitad del recipiente,
                    que finalmente será colocado sobre la base de una fuente de calor, evitando el contacto directo (para
                    ello, utilizaremos la malla metálica presente en la caja). Encenderemos la fuente de calor, procurando
                    generar un calor suave, y cubriremos la cápsula de porcelana con un vidrio de reloj que contendrá cubos
                    de hielo. Al cabo de unos minutos de calefacción, observaremos pequeños cristales de cafeína depositados
                    sobre la base del vidrio de reloj. Como consecuencia del calor entregado, la cafeína en estado sólido
                    presente en las hebras de té sublima y estos “vapores de cafeína” vuelven a solidificarse cuando chocan
                    con la superficie fría de la base del vidrio de reloj.
                  </p>


                  <p align="center">
                    <img
                      src={process.env.PUBLIC_URL + '/img/primers/sublimacion003.jpg'}
                      alt="extraccion - fig3 - Dispositivo utilizado para la sublimación"
                    />
                    <br />
                    <u>Figura 3</u>: Dispositivo utilizado para la sublimación
                  </p>

                  <p>
                    <strong>BIBLIOGRAFÍA</strong>
                  </p>
                  <ul >
                  <li>
                    · Chang, R., Goldsby, K. A., Álvarez Manzo, R., y Ponce López, S. (2013). Química (11a. ed.). México
                    D.F.: McGraw Hill.
                  </li>
                  <li>
                    · Alsina, D., Cagnola, E., Güemes, R., Noseda, J. C. y Odetti, H. (2012). Química: conceptos
                    fundamentales. Santa Fe: Ediciones UNL.
                  </li>
                  <li>
                    · Kurman, L. G. (1990). Química Orgánica. Fundamentos teórico-prácticos del laboratorio. Buenos Aires:
                    Eudeba.
                  </li>
                  <li>
                    · Larpin, D. (2019). Aislamiento y purificación de cafeína. Guía de Trabajo Práctico de Química Orgánica.
                    Facultad de Bioquímica y Ciencias Biológicas. Universidad Nacional del Litoral.
                  </li>
                  <li>
                    · Vogel, A., Furniss, B., Hannaford, P., Smith, P. y Tatchell, A. (1989). Vogel's Textbook of Practical
                    Organic Chemistry. New York: John Wiley.
                  </li>
                  <li>· Bruneton, Jean. (1991). Elementos de Fitoquímica y Farmacognosia. Zaragoza: Acribia.</li>
                  </ul>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Footer */}
      <FooterPrimer horizontal="horizontal" />
      </div>
    </div>
  );
};

export default Sublimacion;
